
import { formatToDatatable } from "@/common/formaterToDatatable";
import { defineComponent, computed, reactive, ref, onMounted } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { getModule } from "vuex-module-decorators";
import TableBillCreatedModule from "@/store/modules/bill/modules/table-bill-created";
import InputText from "@/components/forms/InputText.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import { base64ToBlob } from "@/core/shared/functions/FileTools"
import { Form } from 'vee-validate';
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import { useRoute } from 'vue-router'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CancelBilling from "@/views/billing/cancel/cancel-billing.vue";
import ParcilidadesBilling from "@/views/billing/pagoparcialidades/parcialidades-billing.vue";
import SendToSaleModal from "@/views/sales/modals/SendToSaleModal.vue";

import { useStore } from 'vuex';
import { getModule as getInvoiceModule } from 'vuex-module-decorators';
import InvoiceModule from "@/store/modules/bill/modules/complemento-bill/InvoiceModule";
import ApiService from "@/core/services/ApiService";
import Select from "@/components/forms/Select.vue";
import {toggleCollapse} from "@/core/helpers/dom";

export default defineComponent({
    components:{
        Select,
        CancelBilling,

        ModuleMaster
        , Datatable
        , Datarow
        //, FilterBillCreated
        , InputText
        , DatePickerRange
        , ParcilidadesBilling
        , Form
    },
    setup(){

        //identificadores de los registro de los cuales se esta descargando el documento
        const downloadDocs = ref([] as Array<string>);
        const moduleTable = getModule(TableBillCreatedModule)
        const moduleForm = getModule(NewBillModule)
        moduleTable.SEARCH_TABLE_DATA();
        const route = useRoute();
        const form = ref();

        const store = useStore();
        const invoiceStore = getInvoiceModule(InvoiceModule, store);

        const openModalParcialidadBilling = ref(HTMLButtonElement);
        const openModalCancelBilling = ref(HTMLButtonElement);

        const opciones = ref<any>([
            { id: "Pago de Una Sola Exhibición" },
            { id: "Pago en Parcialialidades" },
            { id: "Todos" }
        ]);

        const handlerCancelBilling = () => {
            if(openModalCancelBilling.value){
                (openModalCancelBilling.value as unknown as HTMLButtonElement).click();
            }
        }

        const handlerParcialidadBilling = () => {
            if(openModalParcialidadBilling.value){
                (openModalParcialidadBilling.value as unknown as HTMLButtonElement).click();
            }
        }

        const handleCrudAction = (id: string, type: string) => {
            switch (type) {
                case 'CB':
                    handlerCancelBilling();
                    break;
                case 'PB':
                    invoiceStore.updateSelectedId(id);
                    handlerParcialidadBilling();
                    break;
            }
            //handlerCancelBilling();
        }

        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "N° Factura", Type: "text", VisibleInGrid: true },
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "date", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true},
            { PropName: "cancellationDate", HeadLabel: "Fecha Cancelación", Type: "text", VisibleInGrid: true, Badge: true},
            { PropName: "statusName", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true,  },
            { PropName: "paymentType", HeadLabel: "Tipo", Type: "text", VisibleInGrid: true,  },
            { PropName: "subTotal", HeadLabel: "Subtotal", Type: "moneda", VisibleInGrid: true },
            { PropName: "tax", HeadLabel: "I.V.A.", Type: "moneda", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
            { PropName: "paymentType", HeadLabel: "Tipo", Type: "text", VisibleInGrid: false },
        ]);
        const labels = reactive({
            placeHolderToFieldSearch: "Bucar por N° Factura"
        })
        const handleChangePage = (page: number) => {
            //change page
            console.log(page);
        }
        const showDocDowloadBill = async (id: string, folio: string, isPUE: boolean) => {
            if(!isDownload(id) && !isPUE){
                downloadDocs.value.push(id);
                downloadFile(id, folio + '.pdf');
            } else{
                downloadDocs.value.push(id);
                downloadFile(id, folio + '.pdf');

                const url = `Invoice/GetNumComplement/${id}`
                const res = await ApiService.query(url);

                for (let i = 1; i <= res.data; i++) {
                    downloadComplementFile(id, i, folio + '#' + i + '.pdf');
                }
            }
            /*const instance = Swal
             instance.fire({
                html: `<p class="fw-bold">En busca de los archivos del registro con folio:
                            <span class="badge bg-primary fs-5">${folio}</span>
                        </p>
                        <div class="text-center p-5">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>`,
                //icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                },
                //timer: 2000,
                //timerProgressBar: true,
                allowOutsideClick:  () => false
                , allowEscapeKey:  () => false
                , showCloseButton: true
                , allowEnterKey:  () => false,
                onOpen() {
                    setTimeout(() => {
                        instance.close();
                    }, 5000)
                },
            }).then(function (result) {
                console.log(result);
                if (result.dismiss === Swal.DismissReason.close) {
                    console.log('Se cancelo la descarga')
                } else {//se cerro por tiempo
                    console.log('I was closed by the timer')
                }
            });*/
        }
        const isDownload = (id: string) => {
            return downloadDocs.value.includes(id);
        }
        const downloadAll = () => {
            renderRows.value.map(d => {
                showDocDowloadBill(d.ID, d.Cells[0].Value, false)
            })
        }
        const downloadFile = (id: string, fileName: string) => {
            moduleForm.DownloadBillFile(id)
                .then(base64 => {
                    const aElement = document.createElement('a');
                    aElement.setAttribute('download', fileName);
                    const href = URL.createObjectURL(base64ToBlob(base64));

                    aElement.href = href;
                    aElement.setAttribute('target', '_blank');
                    aElement.click();
                    URL.revokeObjectURL(href);
                }).finally(() => {
                //removemos el registro que ya se descargo
                downloadDocs.value = downloadDocs.value.filter(d => d != id);
            })
        };

        const downloadComplementFile = async (id: string, numberComplement: number, fileName: string) => {
            const url = `Invoice/GetFileComplement?id=${id}&numComplement=${numberComplement}`
            const res = await ApiService.query(url)

            const aElement = document.createElement('a');
            aElement.setAttribute('download', fileName);
            const href = URL.createObjectURL(base64ToBlob(res.data));

            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
        };

        const searchBills = (filters: Record<string, any>) => {
            switch (filters.payment) {
                case 'Pago de Una Sola Exhibición':
                    filters = { ...filters, type: "Pago de Una Sola Exhibición" };
                    break;
                case 'Pago en Parcialialidades':
                    filters = { ...filters, type: "Pago en Parcialialidades" };
                    break;
                case 'Todos':
                    filters = { ...filters, type: null };
                    break;
                default:
            }

            moduleTable.UPDATE_FILTERS(filters);
            toggleCollapse("#contain-advance-search");
        }

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        // variables del store
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.records, configTable));

        //const renderRows = computed(() => formatToDatatable(moduleTable.records, configTable));
        const loading = computed(() => moduleTable.loading);

        onMounted(() => {
            setCurrentPageBreadcrumbs("Facturas Creadas", ["Módulos", "Facturación"]);

            uppdateTable()
        })

        const uppdateTable = () => {
            //const params = route.params;
            let makeConsult = false;//indica si se hace la consulta
            let request = {};
            const query = route.query;
            //console.log(params);
            const { saleFolio, numBill, dateStart, dateEnd, clientId, rfc, isSend, type} = route.query;

            request = { ...request, isSend: null };
            request = { ...request, type: null }

            if(saleFolio && saleFolio.length > 0){
                request = { ...request, saleFolio }
                form.value.setFieldValue("saleFolio", saleFolio);
            }if(numBill && numBill.length > 0){
                request = { ...request, folio: numBill }
                form.value.setFieldValue("folio", numBill);
            } if (dateStart && dateStart.length > 0) {
                request = { ...request, dateStart }
                form.value.setFieldValue("dateStart", dateStart);
            } if (dateEnd && dateEnd.length > 0) {
                request = { ...request, dateEnd }
                form.value.setFieldValue("dateEnd", dateEnd);
            } if (clientId && clientId.length > 0) {
                request = { ...request, clientId }
                form.value.setFieldValue("clientId", clientId);
            } if (rfc && rfc.length > 0) {
                request = { ...request, rfc }
                form.value.setFieldValue("rfc", rfc);
            }

            if(Object.keys(request).length > 0){
                moduleTable.UPDATE_FILTERS(request);
            }
        }

        return {
            SendToSaleModal,
            openModalCancelBilling,
            openModalParcialidadBilling,
            handleCrudAction,
            totalRecords
            , sizePage
            , opciones
            , renderRows
            , headers
            , labels
            , loading
            , handleChangePage
            , showDocDowloadBill
            , isDownload
            , downloadAll
            , searchBills
            , form
        }
    }
})
